import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { Link } from 'gatsby';
import { EuiSpacer } from '@elastic/eui';
import YoutubeVideo from 'components/Quick_Installation/Live_Video/Troubleshooting/GDATA/VideoGDATA';
import NavButtons from 'components/Quick_Installation/NavButtons';
import ForumBox from 'components/Quick_Installation/Live_Video/ForumBox';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "GDATA Internet Security",
  "path": "/Quick_Installation/Live_Video/Troubleshooting/GDATA/",
  "dateChanged": "2017-11-24",
  "author": "Mike Polinowski",
  "excerpt": "Set an Exception for your IP Camera",
  "image": "./QI_SearchThumb_LiveVideo_GDATA.png",
  "social": "/images/Search/QI_SearchThumb_LiveVideo_GDATA.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_QI-Live_Video_white.webp",
  "chapter": "Installation"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <SEOHelmet title='GDATA' dateChanged='2017-11-22' author='Mike Polinowski' tag='INSTAR IP Camera' description='Use the Flash Video Plugin to view your Camera`s Live Video' image='/images/Search/QI_SearchThumb_LiveVideo.png' twitter='/images/Search/QI_SearchThumb_LiveVideo.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Schnell_Installation/Live_Video/Problembehebung/GDATA/' locationFR='/fr/Quick_Installation/Live_Video/Troubleshooting/GDATA/' crumbLabel="GDATA" mdxType="BreadCrumbs" />
    <NavButtons mdxType="NavButtons" />
    <h2 {...{
      "id": "set-an-exception-rule-inside-your-gdata-antivirus-software",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#set-an-exception-rule-inside-your-gdata-antivirus-software",
        "aria-label": "set an exception rule inside your gdata antivirus software permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Set an Exception rule inside your GDATA Antivirus Software`}</h2>
    <YoutubeVideo mdxType="YoutubeVideo" />
            
    <p>{`It can unfortunately happen that your virus scanner GDATA blocks the video stream of the camera or prevents the ActiveX control or the Quicktime plugin from playing back the stream accordingly. In this case it is necessary to set an exception rule in Kaspersky so as not to block the camera.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "879px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/732679ffcc199b526e7b55f767f80139/bcb8c/GDATA_01.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "56.95652173913044%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAA5UlEQVQoz62RO46DMBRFDTuYJgGhVIiCMl2WM9nDRJbBwDIo+EgTEURAiCZboIBuZPZzR3Y+XZrg4ujJtnz0ri75PZ/xfTzi53RCGIbgnCMIAjVDzpEwhoBSdZYwxhRRFCGOY/WHUqpmkiQgVVVhGAbkeQ5CyHqKooCUZlmmLkzThGEYH0PKskTTNEjTVM+GUth1nT5hXdfo+/4VeTXjOGKeZxVbi1DKlmVB27Z6hNM0QQiB9nq9t7yiYdXyc8PmIdQS+U8I3C4XHHZf2O8sbLYWLOuObdtvke+O48B1Xfi+D8/z8A9PATxz1wJHRwAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/732679ffcc199b526e7b55f767f80139/e4706/GDATA_01.avif 230w", "/en/static/732679ffcc199b526e7b55f767f80139/d1af7/GDATA_01.avif 460w", "/en/static/732679ffcc199b526e7b55f767f80139/401c6/GDATA_01.avif 879w"],
              "sizes": "(max-width: 879px) 100vw, 879px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/732679ffcc199b526e7b55f767f80139/a0b58/GDATA_01.webp 230w", "/en/static/732679ffcc199b526e7b55f767f80139/bc10c/GDATA_01.webp 460w", "/en/static/732679ffcc199b526e7b55f767f80139/6f82a/GDATA_01.webp 879w"],
              "sizes": "(max-width: 879px) 100vw, 879px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/732679ffcc199b526e7b55f767f80139/81c8e/GDATA_01.png 230w", "/en/static/732679ffcc199b526e7b55f767f80139/08a84/GDATA_01.png 460w", "/en/static/732679ffcc199b526e7b55f767f80139/bcb8c/GDATA_01.png 879w"],
              "sizes": "(max-width: 879px) 100vw, 879px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/732679ffcc199b526e7b55f767f80139/bcb8c/GDATA_01.png",
              "alt": "GDATA Internet Security",
              "title": "GDATA Internet Security",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p><strong parentName="p">{`Step 1`}</strong>{`: If GDATA blocks the video stream, the IP address of the camera must be added to the trusted pages.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "879px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/13c071f5d31a05933d87d24baab000b8/bcb8c/GDATA_02.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "62.60869565217391%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAANCAYAAACpUE5eAAAACXBIWXMAAA7CAAAOwgEVKEqAAAACoUlEQVQ4y5WRS4gUVxSG7yKIq+yCunECigTULFTIImbjQpAYAokE0b07FyIaQ1ATcO/GjSI6DnmJbpTIPIwLRenBwQzjTJpB256uflR3ddfj1qO7quv1Sd0eBwko+MPHOdz7czj/vWJmy26mt+zi/o4vmf70c6Y37xzVsZ08+uoAj/d9w6O9B7j/2RfMbN3NnXUb/8cG7q7fxN8fj3Hvk22IuaPHmDz5M//8eZNnPxzh6aHDPNl/kNLB73iy/2tmv/2eB9v38IcQ/CUEN9/BLSG4LT5CrFy4SPNxCaOmUbt2g9rVcWpXrqNdm6A+/huNid9ZuXSZl2cuUP7xV/47dX6N8ulfWDp1jvLp88wd/4l/T5xFvApNHM+jYxjorqTlOSRABsSg+oIXUY8+kK6eD4HB6l0hI8mxADG7PIdu6LR1nWarziu9SqvTVLiOQ+B5eK5kdvkp1UaVhlZDrzcw2m2WtWVMs0sgXXwp8aSLKKZ3fBOnH5DmECVDer5FMByQk/O25MDF9hwC3yeKIpyBxAxssjxb84iiXamW0SYnGSyVsQOHht3GC32GSaxMQRAwNTWFpmnoRZJmU1Wj18W0TBzHUfi+P9qw1WvQrVQIOoYaVGz3RnmO2iBNUrV5xzIIPJ9oGOH0XYoQeZ6TZZmqIkkTBnFElCbqI9IsJU5idVlQqBiYZ7mK17V7uI7E7/tYfbnmG3lzxMzzh5g9E6NjoOl1qnqNbreLlA6drkmz3cOybUqlEjWtRrvTptVqKY+9GlVKqTymLRFeGGBJH8PysN2AfhgSRrGKkKYZcZKqPkkS0jRdJRlFzPK3zkaoN4zihCCMGUQxgzCmH8ZrcT9Uol6vo6sIBrZtYVsjTNN8L5ZlqZ9eWFhgcXGR+fl5KpUKrwFqjIq0CP7lWwAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/13c071f5d31a05933d87d24baab000b8/e4706/GDATA_02.avif 230w", "/en/static/13c071f5d31a05933d87d24baab000b8/d1af7/GDATA_02.avif 460w", "/en/static/13c071f5d31a05933d87d24baab000b8/401c6/GDATA_02.avif 879w"],
              "sizes": "(max-width: 879px) 100vw, 879px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/13c071f5d31a05933d87d24baab000b8/a0b58/GDATA_02.webp 230w", "/en/static/13c071f5d31a05933d87d24baab000b8/bc10c/GDATA_02.webp 460w", "/en/static/13c071f5d31a05933d87d24baab000b8/6f82a/GDATA_02.webp 879w"],
              "sizes": "(max-width: 879px) 100vw, 879px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/13c071f5d31a05933d87d24baab000b8/81c8e/GDATA_02.png 230w", "/en/static/13c071f5d31a05933d87d24baab000b8/08a84/GDATA_02.png 460w", "/en/static/13c071f5d31a05933d87d24baab000b8/bcb8c/GDATA_02.png 879w"],
              "sizes": "(max-width: 879px) 100vw, 879px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/13c071f5d31a05933d87d24baab000b8/bcb8c/GDATA_02.png",
              "alt": "GDATA Internet Security",
              "title": "GDATA Internet Security",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p><strong parentName="p">{`Step 2`}</strong>{`: Open the GDATA program by clicking the GDATA icon in the lower right of the taskbar or by selecting the program from the Start menu. Then, in GDATA, click Web Protection.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "879px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/30a58c182855d5e8d9d23de6571a26b0/bcb8c/GDATA_03.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "62.60869565217391%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAANCAYAAACpUE5eAAAACXBIWXMAAA7CAAAOwgEVKEqAAAACsklEQVQ4y5WRTYgcRRTH6ySevBq8xG/JQQWDnoIXBTGoEDCoZ8F7yMnNQTFCzl70IousJq4HDzmYJbuLh2R2Q4YEElczE9Gd6Z7+mK7p7uru6p6e6Y/5Sfd+GEQPPvjXv169f9V7r55Yf/o4V595mY3nT7D+xItcffwF1p/c4+uvnqTz2jtcO/EmG8++wvpTL3H5oSP/wKNcfvgxfnrkKKtHnkN03/uQK0uf8vPF77l9+gO6777P1htvc+OtU2y9fpKbp06zeew4l4RgVQh++Bc05z8KwVdCIAYXvsDu3kbuDhgur2Asf4uxvIL5zXeMVi5hXVxl8OXX/HHuAr1zn3Pv4/P0DrDU+J/RWzrPnbOfsPbRGcTuLEDFCWPPw0kibK2ogBoogHKf+7lk+oA/B7J9fwGEwJ2hhejev4XrOYxdF8u1+NPZxfZs7LFNrBRZkpDEETf6XYbWgJExxDFNpOvSN+7j+5I0isnTlN5v9xAATjRGRYpyXpAXM6QOyIopizb336amMWGi0IlmNp8RTiP8NKSqqzY+Go0QRVnwu7uL5VhkaYYXSdxYkuSaoipbodaatbU1zJGJ7dhYloXjOHgTiR/4hGHYanr9PqKuKuRYYtsejiNxLJdUaw6KWywW1HVNVVZMkgAvkKQ6bStUWdzqmnhjpmkiivkc//pNrM0tRptbeBsdaq0pF4vmtVZYL+pmYaIDpJoQqwidpQRZdJjwsOXNnWtIb8LYcTFsk4FjoFRIpBRj6WO5E4IgZHt7G8MwcF0X27aRUhKqEKVUizhOuPvLr4h4qvGVxgsTwiQlzXPy2bzNWlYVRVnt7cuCqiypqopyn9uv2PcbGw6He1PO5yVpXjDNC7KGZ+VBt//LHMdGNH03E5tMZDutMAxaBIGP7/83Gm1zb2dn5xCdToe/AJNVhjs8GRPnAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/30a58c182855d5e8d9d23de6571a26b0/e4706/GDATA_03.avif 230w", "/en/static/30a58c182855d5e8d9d23de6571a26b0/d1af7/GDATA_03.avif 460w", "/en/static/30a58c182855d5e8d9d23de6571a26b0/401c6/GDATA_03.avif 879w"],
              "sizes": "(max-width: 879px) 100vw, 879px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/30a58c182855d5e8d9d23de6571a26b0/a0b58/GDATA_03.webp 230w", "/en/static/30a58c182855d5e8d9d23de6571a26b0/bc10c/GDATA_03.webp 460w", "/en/static/30a58c182855d5e8d9d23de6571a26b0/6f82a/GDATA_03.webp 879w"],
              "sizes": "(max-width: 879px) 100vw, 879px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/30a58c182855d5e8d9d23de6571a26b0/81c8e/GDATA_03.png 230w", "/en/static/30a58c182855d5e8d9d23de6571a26b0/08a84/GDATA_03.png 460w", "/en/static/30a58c182855d5e8d9d23de6571a26b0/bcb8c/GDATA_03.png 879w"],
              "sizes": "(max-width: 879px) 100vw, 879px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/30a58c182855d5e8d9d23de6571a26b0/bcb8c/GDATA_03.png",
              "alt": "GDATA Internet Security",
              "title": "GDATA Internet Security",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p><strong parentName="p">{`Step 3`}</strong>{`: Open the GDATA program by clicking the GDATA icon in the lower right of the taskbar or by selecting the program from the Start menu. Then, in GDATA, click Web Protection.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "877px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/940830dbec9e4f27b2e181609b75bd00/4b446/GDATA_04.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "62.60869565217391%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAANCAYAAACpUE5eAAAACXBIWXMAAA7CAAAOwgEVKEqAAAACRUlEQVQ4y42T204TURSG59k0eGU0cmM8RQKYqGhjQPGGACa8BDFpX4I7pBcEUjXxAuECWxKBdg6dzkz3nPYcOjOfmeEQ64Gwki//2js7a/1ZWVtZXV1lZWWFtbW1SpeWllh8XWNx7hXL82/58G6R5fkFFmafMT89y4sHj/7i5cPH1J5O8WZ6BqXeqFOv19nY2KDRaPBxfZ2d2nu+ztZoPXnOl5karak5tiYm2Zq4R/PWJM2JM8rzp5t32bxxp9Lm7fsozc0t2u02mqbRarXY3dnF1nW0n8e4AwthDhD9Aa7tVHjOkEAI/KEg8n0oCi4jL1BOhYYYCgzDwLQGdPUeMorQDQNV0+ipapX3emqVa5qOYfTxgwCZJKSeh/zRIWp3yFwX5XPnG5qpo+s6PUPl8LRDEAa4wmXoODiOg5QSz/WqxmEYYls2YRCQZhmRZdHb3kHbbTEaWCilU901GQpBNsrww4Aojrhu5IAIQ1wpq1zJ8oxjq4uqqaRJiikGBDKsHhdFcTVlwSwjG40qijw/c6gODSzbQoYS23WQsby+wzwfa1I5lElEHMcUeUEURdXMSi3vLvj97kLLYmmaXhavCm7ub2NZFn2jz4ne5fCkfWa/KMa6/+kkzwtsETBwXGxHoKkqSZKguNLHGvr0LRfHCxG+j+cHY+7+RRTFCNfHER5D4eLYNr7vn80wTkYEUYqMU2SU4ocxWZaR51ml/2Nsqc9D6Xa71S8xTfN87+xq92zbvpLyTbm73/f3OTg4YG9vj6OjI34BR3yt5qsAp5YAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/940830dbec9e4f27b2e181609b75bd00/e4706/GDATA_04.avif 230w", "/en/static/940830dbec9e4f27b2e181609b75bd00/d1af7/GDATA_04.avif 460w", "/en/static/940830dbec9e4f27b2e181609b75bd00/0a597/GDATA_04.avif 877w"],
              "sizes": "(max-width: 877px) 100vw, 877px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/940830dbec9e4f27b2e181609b75bd00/a0b58/GDATA_04.webp 230w", "/en/static/940830dbec9e4f27b2e181609b75bd00/bc10c/GDATA_04.webp 460w", "/en/static/940830dbec9e4f27b2e181609b75bd00/d7666/GDATA_04.webp 877w"],
              "sizes": "(max-width: 877px) 100vw, 877px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/940830dbec9e4f27b2e181609b75bd00/81c8e/GDATA_04.png 230w", "/en/static/940830dbec9e4f27b2e181609b75bd00/08a84/GDATA_04.png 460w", "/en/static/940830dbec9e4f27b2e181609b75bd00/4b446/GDATA_04.png 877w"],
              "sizes": "(max-width: 877px) 100vw, 877px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/940830dbec9e4f27b2e181609b75bd00/4b446/GDATA_04.png",
              "alt": "GDATA Internet Security",
              "title": "GDATA Internet Security",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p><strong parentName="p">{`Step 4`}</strong>{`: Click New to set a new exception.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "880px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/d686a0128123e6fd5aefcf58e9fa58ea/9c177/GDATA_05.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "62.60869565217391%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAANCAYAAACpUE5eAAAACXBIWXMAAA7CAAAOwgEVKEqAAAACd0lEQVQ4y4WTSU8UQRiG57d5MBoPRhP14sUIB2IYE8IJGeCANyccOLEFwk/gpogRCENMNGHRyDJimOnZel+ql5nu6sdMDZyAWMmTWpJ6632/qirMzMwwPT3N7OwsU1NTlEolxsfHGRsbY2JiglJpUvVvi0WKo6MMDQ3dYHh4mJGREYrFIoW1tTVWVlbY2NhgdXWV5eVl1tfXWVpaYmFhQc0XFxeZm5u7lXK5rPhQLjM/P09ha/MzJycnNJtN9iv77FcqGLpOvaZh2zZmR8fUDSzDwLJtXM/D9X0c18MXgizPkbmEfi8lhZrbxHNdOu0OHUOnqbeIk4RGXaMy+Z7tV2/YeT2q2Hryko/3HrN5/ylfHjzj66MX7D58zvd3s4huSiZzCvtnP5RIq9VCazc4b1wgQoHjebQr3xC7FYKdPcKjn4R1Df+0in/2B/+sindaJTir0vj1m1qjQy/NKAC0fR3bdch6KXE3Jk7i/jL6ySl/d/YwD45IDYMMbiABP4pwHWcQuZ//0tTQmhrdpIsVOIg4BCkxvIC65WMnGZEEkWSIrkQkkuBqHPZyvCAiS1PyPB84bDhtTMskFCFe6CuHMpPEUQQ5ZGk2QEoVq2m4ilbHoFar4TgOuZQDwUxmRN2YJEmU5V7aU4JRP4br4nmeoh/pemw7Do7rIoRQYnE8KJES/HS0jWEY6pYvW3Wq2gXplf27uN7s+746tE9DayhTBT8OsNyAjulhe4IgCgmjWIn+jzAMEYFABIFy3neqapj0UsK4p4jiHiLqqnrJ/3BbK9TrdfVLdL2DY9vYloVtW1jW3ZimqX5R/+0eHh5yfHzMwcEB5+fn/AMK45+9QrmHCQAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/d686a0128123e6fd5aefcf58e9fa58ea/e4706/GDATA_05.avif 230w", "/en/static/d686a0128123e6fd5aefcf58e9fa58ea/d1af7/GDATA_05.avif 460w", "/en/static/d686a0128123e6fd5aefcf58e9fa58ea/59bec/GDATA_05.avif 880w"],
              "sizes": "(max-width: 880px) 100vw, 880px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/d686a0128123e6fd5aefcf58e9fa58ea/a0b58/GDATA_05.webp 230w", "/en/static/d686a0128123e6fd5aefcf58e9fa58ea/bc10c/GDATA_05.webp 460w", "/en/static/d686a0128123e6fd5aefcf58e9fa58ea/fd35f/GDATA_05.webp 880w"],
              "sizes": "(max-width: 880px) 100vw, 880px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/d686a0128123e6fd5aefcf58e9fa58ea/81c8e/GDATA_05.png 230w", "/en/static/d686a0128123e6fd5aefcf58e9fa58ea/08a84/GDATA_05.png 460w", "/en/static/d686a0128123e6fd5aefcf58e9fa58ea/9c177/GDATA_05.png 880w"],
              "sizes": "(max-width: 880px) 100vw, 880px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/d686a0128123e6fd5aefcf58e9fa58ea/9c177/GDATA_05.png",
              "alt": "GDATA Internet Security",
              "title": "GDATA Internet Security",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p><strong parentName="p">{`Step 5`}</strong>{`: Enter the IP address of your INSTAR IP camera here.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "879px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/410b62aa83b72d9f04c754a1efd8b9c8/bcb8c/GDATA_06.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "62.173913043478265%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAMCAYAAABiDJ37AAAACXBIWXMAAA7CAAAOwgEVKEqAAAACJUlEQVQoz42Sy2oUQRSG+8WU4A1d6MatCl4WvkFQzEQESTJJJsYLQVAhb+Aui/gAIm7cJNGZzC3T9/v0bbp6uj/pCtGJiqTgo079dfjPOUUpzWaTVqslebm5ydrGBisLDZq377F65z5rt+7y4sFDnl2/yZMLV3k0d5n5cxeZP3/pFI/nrtC4dgNlfX2dra0tdnZ2+LS7y/vtbT6++0B/7Q2d5iv6q68ZbL6l+7xFe2GJztMVDhdXaDeWJJ3FZUm7sSxzlOFwSE0QBNi2TafdQdV1gnyCEYZ4kwwnjrGTBKeOJxl+IYiBhL+X8l0/5GvvG6ZuMBqNaB916WsDojBk2O8z6PXQVQ3bMBl0e1iGwbDXxzEtsiSRGJaHqjtkWY4SZTH22MXwLXzfxx8HWIFDkiS4jis1IQR5nh/HuZB6EISUZSmxbQddNxCiQKnbLKuSgTPC9/zjhMChqirOsuq8ySQjTRPK6RRFCkVOzxpiWzZpmmJ4pjQ+g5vMOyle78rJnZcEZGlGVVZESUQuBEVR/KIeW8xqQlDb1GWrmW6Vrjlg7+gHSRQThAGGa2E6FtPpVL7biVEdz55FUZCbJlF/QGpZjKNI6sqRo3KgHmJaHpphM7INDMeUhrMdnqLutCyJ9w9wP3/B3dtnpGmEQfB75CgVhIlgklfEqSCK01Oj/ovij3eWI3ueh+sef48wDBmPx5Ioiv5LXO/jMYZhoGkaqqpiWRY/ARFOapibWaqMAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/410b62aa83b72d9f04c754a1efd8b9c8/e4706/GDATA_06.avif 230w", "/en/static/410b62aa83b72d9f04c754a1efd8b9c8/d1af7/GDATA_06.avif 460w", "/en/static/410b62aa83b72d9f04c754a1efd8b9c8/401c6/GDATA_06.avif 879w"],
              "sizes": "(max-width: 879px) 100vw, 879px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/410b62aa83b72d9f04c754a1efd8b9c8/a0b58/GDATA_06.webp 230w", "/en/static/410b62aa83b72d9f04c754a1efd8b9c8/bc10c/GDATA_06.webp 460w", "/en/static/410b62aa83b72d9f04c754a1efd8b9c8/6f82a/GDATA_06.webp 879w"],
              "sizes": "(max-width: 879px) 100vw, 879px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/410b62aa83b72d9f04c754a1efd8b9c8/81c8e/GDATA_06.png 230w", "/en/static/410b62aa83b72d9f04c754a1efd8b9c8/08a84/GDATA_06.png 460w", "/en/static/410b62aa83b72d9f04c754a1efd8b9c8/bcb8c/GDATA_06.png 879w"],
              "sizes": "(max-width: 879px) 100vw, 879px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/410b62aa83b72d9f04c754a1efd8b9c8/bcb8c/GDATA_06.png",
              "alt": "GDATA Internet Security",
              "title": "GDATA Internet Security",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p><strong parentName="p">{`Step 6`}</strong>{`: And confirm the input and reload the web interface of the camera.`}</p>
    <p>{`Finally, restart the web interface of the camera. You can do this by updating the address in the web browser or by closing the web browser, and then entering the IP address again in the web browser`}</p>
    <p>{`If you have any questions or problems with the settings, you can reach us at any time within our office hours. We will do our best to solve your problems as quickly as possible.`}</p>
    <EuiSpacer mdxType="EuiSpacer" />
    <ForumBox mdxType="ForumBox" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      